import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ReactCountryFlag from 'react-country-flag';

import nextRaceStyles from './next-race.module.css';

export const query = graphql`
  {
    allPrismicRaceEvent(
      limit: 50
      sort: { fields: data___start_date, order: ASC }
      filter: { data: { confirmed: { eq: true } } }
    ) {
      edges {
        node {
          id
          data {
            car
            confirmed
            end_date
            name
            race_format
            series_or_championship_name
            start_date
            team
            track {
              id
              document {
                ... on PrismicTrack {
                  data {
                    country
                    name
                    track_map {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function NextRace({ showCalendarCta, showTrackMap }) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const raceEvents = data.allPrismicRaceEvent.edges.map(entry => entry.node.data);

        const nextRace = raceEvents.find(raceEvent => {
          return new Date(raceEvent.start_date) > yesterday;
        });

        if (!nextRace) {
          return (
            <div className={nextRaceStyles.nextRace}>
              <div className={nextRaceStyles.container}>
                <h2>Next race</h2>

                <p>There are no upcoming races.</p>
              </div>
            </div>
          );
        }

        if (nextRace.track.document) {
          nextRace.track = nextRace.track.document.data;
        }

        return (
          <div className={nextRaceStyles.nextRace}>
            <div className={nextRaceStyles.container}>
              <h2>Next race</h2>

              {nextRace.track.country && (
                <ReactCountryFlag
                  className={nextRaceStyles.flag}
                  countryCode={nextRace.track.country}
                  svg
                  style={{
                    width: '2em',
                    height: '2em',
                  }}
                  title={nextRace.track.country}
                />
              )}

              <h3 className={nextRaceStyles.trackName}>{nextRace.track.name}</h3>

              {nextRace.track.track_map.url && showTrackMap && (
                <img
                  className={nextRaceStyles.trackMap}
                  alt={'Map of ' + nextRace.track.name}
                  src={nextRace.track.track_map.url}
                ></img>
              )}

              <div className={nextRaceStyles.date}>
                <span>
                  {new Date(nextRace.start_date).toLocaleDateString()}{' '}
                  {nextRace.end_date ? ' - ' + new Date(nextRace.end_date).toLocaleDateString() : ''}
                </span>
              </div>

              <div className={nextRaceStyles.championshipName}>
                <span>{nextRace.series_or_championship_name}</span>
              </div>

              {showCalendarCta && (
                <a className={`${nextRaceStyles.calendarCta} button`} href="/calendar">
                  See the full calendar
                </a>
              )}
            </div>
          </div>
        );
      }}
    />
  );
}
