import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import HeaderMenu from '../header-menu/header-menu';

import homeHeaderStyles from './home-header.module.css';

export const query = graphql`
  {
    prismicHeaderMedia {
      data {
        poster_image {
          url
        }
        video {
          url
        }
      }
    }
  }
`;

export default function HomeHeader() {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <>
          <HeaderMenu activePageName="home" />

          <div className={homeHeaderStyles.homeHeader}>
            <video
              className={homeHeaderStyles.video}
              role="presentation"
              playsInline
              preload="auto"
              muted
              loop
              autoPlay
              width="2541"
              height="1429"
              src={data.prismicHeaderMedia.data.video.url}
              poster={data.prismicHeaderMedia.data.poster_image.url}
            />

            <div className={homeHeaderStyles.overlay}>
              <div className={homeHeaderStyles.container}>
                {/* Next race */}
                {/* <div className={homeHeaderStyles.nextRaceContainer}>
                  <span className={homeHeaderStyles.nextRaceLabel}>Next race</span>

                  <div className={homeHeaderStyles.nextRaceNumberOfDaysContainer}>
                    <span className={homeHeaderStyles.nextRaceNumberOfDays}>135</span>
                    <span className={homeHeaderStyles.nextRaceNumberOfDaysLabel}>days</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    />
  );
}
