import React from 'react';

import SiteMetadata from '../components/site-metadata/site-metadata';
import HomeHeader from '../components/home-header/home-header';
import About from '../components/about/about';
import NextRace from '../components/next-race/next-race';
import RaceReportList from '../components/race-report-list/race-report-list';
import Footer from '../components/footer/footer';
import SponsorsFooter from '../components/sponsors-footer/sponsors-footer';

export default function Home() {
  return (
    <div>
      <SiteMetadata title="Home" image="/images/home-meta-image.jpg" />

      <HomeHeader />
      <About />
      <NextRace showCalendarCta={true} showTrackMap={true} />
      <RaceReportList limit={3} />
      <SponsorsFooter />
      <Footer />
    </div>
  );
}
