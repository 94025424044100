import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import aboutStyles from './about.module.css';

export const query = graphql`
  {
    prismicHomeAbout {
      data {
        content {
          html
        }
        image {
          url
        }
      }
    }
  }
`;

export default function About() {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <div className={aboutStyles.about}>
          <div
            className={aboutStyles.bio + ' prose prose-lg'}
            dangerouslySetInnerHTML={{ __html: data.prismicHomeAbout.data.content.html }}
          ></div>
          <div
            className={aboutStyles.photo}
            style={{ backgroundImage: `url('${data.prismicHomeAbout.data.image.url}')` }}
          ></div>
        </div>
      )}
    />
  );
}
