import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import raceReportListStyles from './race-report-list.module.css';

export const query = graphql`
  {
    allPrismicBlogPost(
      limit: 20
      sort: { order: DESC, fields: first_publication_date }
      filter: { data: { excerpt: { raw: {} } } }
    ) {
      edges {
        node {
          first_publication_date
          data {
            displayed_publish_date
            main_image {
              thumbnails {
                hero_banner {
                  url
                }
              }
            }
            title {
              text
            }
            excerpt {
              html
            }
          }
          uid
        }
      }
    }
  }
`;

export default function RaceReportList(props) {
  return (
    <StaticQuery
      query={query}
      render={data => {
        let posts = data.allPrismicBlogPost.edges
          .map(edge => edge.node)
          .map(post => ({
            ...post,
            first_publication_date: new Date(post.first_publication_date),
            data: {
              ...post.data,
              displayed_publish_date: post.data.displayed_publish_date
                ? new Date(post.data.displayed_publish_date)
                : undefined,
            },
          }));

        if (props.limit) {
          posts = posts.slice(0, props.limit);
        }

        return (
          <div className={raceReportListStyles.container}>
            {posts.map(post => {
              const publishedDate = post.data.displayed_publish_date
                ? post.data.displayed_publish_date
                : post.first_publication_date;

              return (
                <div className={raceReportListStyles.postContainer} key={post.uid}>
                  <a href={`/race-report/${post.uid}`}>
                    <img
                      src={post.data.main_image.thumbnails.hero_banner.url}
                      className={raceReportListStyles.image}
                      alt={post.data.title.text}
                    />
                  </a>

                  <h3 className={raceReportListStyles.title}>
                    <a href={`/race-report/${post.uid}`}>{post.data.title.text}</a>
                  </h3>

                  <span className={raceReportListStyles.publishedDate}>
                    {publishedDate.toLocaleString('default', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </span>

                  <div
                    className={raceReportListStyles.excerpt}
                    // rome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
                    dangerouslySetInnerHTML={{ __html: post.data.excerpt.html }}
                  />

                  <a className={`${raceReportListStyles.readMore} button`} href={`/race-report/${post.uid}`}>
                    Read more
                  </a>
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
}
